<template>
  <div>
    <!-- <this-header /> -->

    <!-- Content -->
    <div class="mt-150">
      <div class="anan-set-nav-conttainer pt-1">
        <router-link :to="{name : 'new-pages'}">
          <b-button
            class="btn btn-gradient-primary mb-1 mr-50 mr-50 d-md-none"
          >
            <i class="fas fa-arrow-left mr-25" /> {{ $t('key-126') }}
          </b-button>
        </router-link>
        <div class="VYJdTQ">
          <a
            class="OFl2GI"
            :title="$t('all')"
            href="javascript:void(0)"
            :class="{ gAImis: isActive === 0 }"
            @click="getData(status = 0, search_val),isActive = 0"
          >
            <span class="_20hgQK">{{ $t('all') }}</span>
          </a>

          <a
            class="OFl2GI"
            :title="$t('noOwner')"
            href="javascript:void(0)"
            :class="{ gAImis: isActive === 1 }"
            @click="getData(status = 1, search_val),isActive = 1"
          >
            <span class="_20hgQK">{{ $t('noOwner') }}</span>
          </a>

          <a
            class="OFl2GI"
            :title="$t('cancel')"
            href="javascript:void(0)"
            :class="{ gAImis: isActive === 2 }"
            @click="getData(status = 2, search_val),isActive = 2"
          >
            <span class="_20hgQK">{{ $t('cancel') }}</span>
          </a>

          <a
            class="OFl2GI"
            :title="$t('pendingReview')"
            href="javascript:void(0)"
            :class="{ gAImis: isActive === 3 }"
            @click="getData(status = 3, search_val),isActive = 3"
          >
            <span class="_20hgQK">{{ $t('pendingReview') }}</span>
          </a>

          <a
            class="OFl2GI"
            :title="$t('operationCompleted')"
            href="javascript:void(0)"
            :class="{ gAImis: isActive === 4 }"
            @click="getData(status = 4, search_val),isActive = 4"
          >
            <span class="_20hgQK">{{ $t('operationCompleted') }}</span>
          </a>
        </div>

        <div class="VrgkXA">
          <i class="fal fa-search" />
          <input
            v-model="search_val"
            autocomplete="off"
            :placeholder="$t('searchByTracking')"
            @input="getData(status, search_val)"
          >
        </div>

        <nav
          class="stardust-tabs-header-wrapper"
        >
          <ul class="stardust-tabs-header">
            <li class="stardust-tabs-header__tab stardust-tabs-header__tab--active">
              <div class="gjTwjy +83iUp" /><div class="_8rBYkP">
                <span>{{ $t('ownerlessGoods') }}</span>
              </div>
            </li>
          </ul>
          <i
            class="stardust-tabs-header__tab-indicator"
            style="display: none; width: 100%; transform: translateX(0%);"
          />
        </nav>
        <b-overlay
          :show="showOver"
          variant="transparent"
          opacity="0.99"
          blur="5px"
          rounded="sm"
        >
          <template #overlay>
            <div class="text-center">
              <p id="cancel-label">
                กรุณารอสักครู่...
              </p>
            </div>
          </template>
          <div class="grid-container">
            <!-- <mall-loader
            v-for="n in 7"
            v-if="!items.length"
            :key="n"
          /> -->
            <div
              v-for="(item,index) in items"
              :key="index"
              class="grid-item"
            >
              <div>
                <div @click="checkItem(item._id)">
                  <div class="H+cO5R">
                    <div class="B1W2VS WWmObc">
                      <div style="pointer-events: none">
                        <div class="KbeBa1 tldoKP">
                          <img
                            width="invalid-value"
                            height="invalid-value"
                            class="qtsfFQ a6jjFR"
                            style="object-fit: contain"
                            :src="item.fileadmin[0]"
                          >
                        </div>
                      </div>
                      <div class="k3EwZe">

                        <div class="-h7lsO">
                          <div class="glgfCR">
                            <div class="sUq1Dr _1M8qaS">
                              {{ $t('number') }} {{ item.itemnumber_text }}
                            </div>
                          </div>
                          <div class="glgfCR">
                            <div class="sUq1Dr _1M8qaS">
                              {{ $t('parcelCode') }} {{ item.postnumber }}
                            </div>
                          </div>
                          <div class="a2AdT4">
                            <div
                              v-if="item.statusconfirm == 0"
                              class="b0Vn+L mr5"
                              style="color: rgb(172, 172, 172)"
                            >
                              {{ $t('cancelled') }}
                            </div>
                            <div
                              v-if="item.statusconfirm == 1"
                              class="b0Vn+L mr5"
                              style="color: rgb(215, 38, 3)"
                            >
                              {{ $t('noOwner') }}
                            </div>
                            <div
                              v-if="item.statusconfirm == 2"
                              class="b0Vn+L mr5"
                              style="color: rgb(0, 162, 255)"
                            >
                              {{ $t('pendingReview') }}
                            </div>
                            <div
                              v-if="item.statusconfirm == 3"
                              class="b0Vn+L mr5"
                              style="color: rgb(0, 202, 88)"
                            >
                              {{ $t('operationCompleted') }}
                            </div>
                            <div
                              v-if="item.statusconfirm == 3"
                              class="b0Vn+L mr5"
                              style="color: rgb(0, 202, 88)"
                            >
                              {{ $t('approvalDate') }}  {{  time(item.confirm_time) }} 
                            </div>
                          </div>
                        </div>
                        <div class="TPwdp+ Cs-zcr mt-1">
                          <div class="GfQFsW KNL5tj">
                            <span class="EnZ1eY">{{ item.lot_name }}/ {{ item.lot_order }}</span>
                          </div>
                          <div class="_6ykn6M aO8bXP">
                            {{ $t('transactionDate') }}  {{ time(item.created_at) }} 
                          </div>
                        </div>
                      </div>
                      <div class="shop-item-card__hover-footer UTiGLm">
                        {{ $t('key-88') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="my-2 pb-1">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRow"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              align="center"
              size="lg"
              @input="getData(status, search_val)"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import { BPagination, BOverlay, BButton } from 'bootstrap-vue'
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import moment from 'moment-timezone'

export default {
  name: 'CustomerNewView',
  components: {
    BPagination,
    // ThisHeader,
    BButton,
    BOverlay,
  },
  data() {
    return {
      showMore: false,
      isActive: 0,
      currentPage: 1,
      perPage: 20,
      // rows: 200,
      info: [],
      items: [],
      totalRow: 1,
      search_val: null,
      status: 0,
      showOver: false,
    }
  },
  mounted() {
    // this.getPreProduct()
    this.getData(this.status, this.search_val)
  },
  methods: {
    time(data) {
      moment.locale('th')
      return moment(data).format('DD MMMM YYYY HH:mm:ss')
    },
    // eslint-disable-next-line camelcase
    async getData(type, search_val) {
      this.showOver = true
      try {
        const params = {
          type,
          search_val,
          currentPage: this.currentPage,
          perPage: this.perPage,
        }
        const { data: res } = await this.$http.get('Goodsconfirm/getDataCus', { params })
        this.items = res.data
        this.totalRow = res.total
        this.showOver = false
        // console.log(res)
      } catch (err) {
        console.log(err)
        this.showOver = false
      }
    },
    GetOrderAll(status) {
      this.isActive = status
      this.$http.get(`/preproduct/list?status=${status}`)
        .then(res => {
          this.info = res.data.data
          // console.log(this.info)
        })
        .catch(err => {
          console.log(err)
        })
    },
    getPreProduct() {
      this.$http.get('/preproduct/list')
        .then(res => {
          this.info = res.data.data
          // console.log(this.info)
        })
        .catch(err => {
          console.log(err)
        })
    },
    checkItem(id) {
      this.$router.push({
        name: 'goodsconfirm-check',
        params: {
          id,
        },
      })
    },
  },
}
</script>

  <style lang="scss" scoped>

  </style>
